import {
  CurrentUserClient,
  ManagementClient,
  TenantsClient,
  UsersClient,
  AuditClient as GatewayAuditClient
} from '@/api/GatewayAPIClient'
import {
  AppPagesClient,
  AppsClient,
  AssetsClient,
  AuditClient,
  BackupsClient,
  ContextObjectsClient,
  DevicesClient,
  FilesClient,
  LanguagesClient,
  LicensesClient,
  QuestionnairesClient,
  SchedulesClient,
  StatisticsClient,
  StudiesClient,
  TenantClient
} from '@/api/TenantAPIClient'

import { AppsClient as FrontendAppsClient } from '@/api/TenantFrontendAPIClient'
import { UsersClient as TenantUsersClient } from '@/api/TenantAPIClient'
import { useAuthStore } from '@/modules/authentication/stores/auth'

const GatewayApiFetchProxy = {
  fetch: async (input: RequestInfo, init: RequestInit) => {
    const { accessToken } = useAuthStore()

    init.headers = {
      ...init.headers,
      Authorization: `Bearer ${accessToken}`
    }

    return fetch(input, init)
  }
}

const TenantApiFetchProxy = {
  fetch: async (input: RequestInfo, init: RequestInit) => {
    const { accessToken } = useAuthStore()

    init.headers = {
      ...init.headers,
      Authorization: `Bearer ${accessToken}`
    }

    return fetch(input, init)
  }
}

export const useCurrentUserClient = () =>
  new CurrentUserClient(window.hsp.GATEWAY_API_BASE_URL, GatewayApiFetchProxy)
export const useTenantsClient = () =>
  new TenantsClient(window.hsp.GATEWAY_API_BASE_URL, GatewayApiFetchProxy)
export const useUsersClient = () =>
  new UsersClient(window.hsp.GATEWAY_API_BASE_URL, GatewayApiFetchProxy)
export const useGatewayAuditClient = () =>
  new GatewayAuditClient(window.hsp.GATEWAY_API_BASE_URL, GatewayApiFetchProxy)
export const useManagementClient = () =>
  new ManagementClient(window.hsp.GATEWAY_API_BASE_URL, GatewayApiFetchProxy)
export const useStudiesClient = () =>
  new StudiesClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useTenantClient = () =>
  new TenantClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useTenantUsersClient = () =>
  new TenantUsersClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useLanguagesClient = () =>
  new LanguagesClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useFilesClient = () =>
  new FilesClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useAssetsClient = () =>
  new AssetsClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useQuestionnairesClient = () =>
  new QuestionnairesClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useContextObjectsClient = () =>
  new ContextObjectsClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useAppPagesClient = () =>
  new AppPagesClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useBackupsClient = () =>
  new BackupsClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useStatisticsClient = () =>
  new StatisticsClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useDevicesClient = () =>
  new DevicesClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useSchedulesClient = () =>
  new SchedulesClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useAuditClient = () =>
  new AuditClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useAppsClient = () =>
  new AppsClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
export const useLicensesClient = () =>
  new LicensesClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)

export const useFrontendAppsClient = () =>
  new FrontendAppsClient(window.hsp.TENANT_API_BASE_URL, TenantApiFetchProxy)
