import { createRouter, createWebHistory } from 'vue-router'
import organizationsRoutes from '@/modules/organizations/router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...organizationsRoutes,
    {
      path: '/endsession',
      redirect: { path: '/' }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { path: '/' }
    },
    {
      name: 'shares-accept',
      path: '/shares/accept',
      component: () => import('@/modules/SharesAccept.vue')
    }
  ]
})

export default router
